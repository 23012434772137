import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    currentView: 'Home'
  },
  mutations: {
    SET_CURRENT_VIEW (state, view: string) {
      state.currentView = view
    }
  },
  actions: {
    setCurrentView (context, view: string) {
      context.commit('SET_CURRENT_VIEW', view)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
