
import NavBarBrandIcon from '@/components/NavBarBrandIcon.vue'

export default {
  name: 'NavBarBrand',
  data () {
    return {
      showNav: false
    }
  },
  components: {
    NavBarBrandIcon
  }
}
