<template>
  <div id="home" class="panel">
    <div class="columns is-flex is-vcentered is-centered">
      <div class="column is-three-fifths">
        <header>
          <h1 class="is-size-2 has-text-weight-semibold">Geoffrey Lalloué</h1>
          <p class="is-size-5">Senior Frontend & Mobile [ developer | mentor | lover ]</p>
        </header>
      </div>
      <div class="column">
        <a href="#work" class="picture">
          <span class="icon is-normal arrow is-size-4"><i class="fas fa-chevron-right" aria-hidden="true"></i><span>See my work</span></span>
          <img src="../assets/images/me.jpg" alt="Profile picture" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

#main > #home {
  padding: 0;
}
#home {
  .columns {
    margin: 0;
    height: 100%;
    .column {
      padding: 0;
    }
  }
  header {
    text-align: left;
    padding: 3.5em 2.5em;
    margin-bottom: 0;
    width: 100%;
    h1 {
      letter-spacing: -0.015em;
      line-height: 1.25em;
      margin-bottom: 0;
    }
    p {
      letter-spacing: -0.015em;
      margin: 0.25em 0 0 0;
      color: $grey;
    }
  }
  .picture {
    .arrow:before {
      font-weight: 900;
    }
    .arrow {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -1.375em;
      width: 2.75em;
      height: 2.75em;
      background: #000;
      background: rgba(0, 0, 0, 0.75);
      color: #ffffff;
      line-height: 2.75em;
      z-index: 1;
      -moz-transition: width .15s ease-in-out, padding-right .15s ease-in-out;
      -webkit-transition: width .15s ease-in-out, padding-right .15s ease-in-out;
      -ms-transition: width .15s ease-in-out, padding-right .15s ease-in-out;
      transition: width .15s ease-in-out, padding-right .15s ease-in-out;
      span {
        display: block;
        text-indent: -9999px;
      }
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
  .picture:hover .arrow {
    width: 3em;
    padding-right: 0.25em;
  }
}

@include until($desktop) {
  #main > #home {
    .picture {
      width: 100%;
      img {
        position: relative;
        width: 100%;
      }
    }
  }
}

@include until($tablet) {
  #main > #home {
    header {
      padding: 2.75em 2em 2.5em 2em;
      text-align: center;
      h1 {
        font-size: $size-3 !important;
      }
      p {
        font-size: $size-6 !important;
        margin: 0.25em 0 0 0;
      }
    }
  }
}

@include from($desktop) {
  #main > #home {
    height: 25em;
  }
}

</style>
