<template lang="html">
  <a :class="'button is-outlined is-primary is-normal ' + color" :href="url">
    <span class="icon is-normal"><i :class="'fab fa-' + icon" aria-hidden="true"></i></span>
  </a>
</template>

<script lang="ts">
export default {
  name: 'NavBarBrandIcon',
  props: ['icon', 'url', 'color']
}
</script>
