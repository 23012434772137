
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NavTab',
  props: ['text', 'img', 'uri'],
  computed: {
    ...mapState(['currentView'])
  },
  methods: {
    ...mapActions(['setCurrentView'])
  }
}
