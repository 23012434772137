
import NavTab from '@/components/NavTab.vue'
import NavBarBrand from '@/components/NavBarBrand.vue'

export default {
  name: 'App',
  components: {
    NavTab,
    NavBarBrand
  }
}
