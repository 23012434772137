<template lang="html">
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item logo" href="http://geoffrey.lalloue.fr">
        <img src="@/assets/images/logo2.png" width="200" height="60" alt="Geoffrey Lalloue personnal website icon">
      </a>

      <a  role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
          data-target="links"
          v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="links" class="navbar-menu" v-bind:class="{ 'is-active' : showNav }">
      <div class="navbar-end">
        <div class="navbar-item">
          <NavBarBrandIcon icon="twitter" url="https://twitter.com/glalloue" color="is-twitter" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="medium" url="https://medium.com/@glalloue" color="is-medium-com" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="facebook" url="https://www.facebook.com/geoffrey.lalloue/" color="is-facebook" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="strava" url="https://www.strava.com/athletes/geoffrey_lalloue" color="is-strava" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="linkedin" url="https://www.linkedin.com/in/geoffreylalloue/" color="is-linkedin" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="viadeo" url="https://viadeo.journaldunet.com/p/geoffrey-lalloue-327935" color="is-viadeo" />
        </div>
        <div class="navbar-item">
          <NavBarBrandIcon icon="github" url="https://github.com/glalloue" color="is-github" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import NavBarBrandIcon from '@/components/NavBarBrandIcon.vue'

export default {
  name: 'NavBarBrand',
  data () {
    return {
      showNav: false
    }
  },
  components: {
    NavBarBrandIcon
  }
}
</script>

<style scoped lang="scss">
.navbar {
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1);
  .navbar-brand {
    background-color: white;
    .logo {
      padding: 0.2rem 0.25rem;
    }
    .navbar-item img {
      max-height: none;
    }
  }
  .navbar-menu {
    .navbar-item {
      padding: 0.25rem 0.25rem;
    }
  }
}

@include until($desktop) {
  .navbar {
    background-color: transparent;
    .navbar-burger {
      height: auto;
      width: 4em;
    }
    .navbar-brand {
      box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1);
    }
    .navbar-menu {
      display: block;
      position: absolute;
      right: 0;
      width: 4em;
      height: auto;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: -4px 4px 8px rgba(10, 10, 10, 0.1);
      transform: translateX(120%);
      transition: all .4s ease-in-out;
      pointer-events: none;
    }
    .navbar-menu.is-active {
      transform: none;
      pointer-events: auto;
    }
  }
}
</style>
