<template lang="html">
  <li :class="{ 'is-active': currentView === this.text }">
    <router-link :to="uri" @click="setCurrentView( text )">
      <span class="icon is-normal"><i :class="'fas fa-' +  img" aria-hidden="true"></i></span>
    </router-link>
  </li>
</template>

<script lang="ts">
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NavTab',
  props: ['text', 'img', 'uri'],
  computed: {
    ...mapState(['currentView'])
  },
  methods: {
    ...mapActions(['setCurrentView'])
  }
}
</script>

<style scoped lang="scss">
  .icon {
    font-size: 2.5rem;
  }

  a {
    position: relative;
    display: inline-block;
    color: #ffffff;
    width: 1em;
    height: 1em;
    line-height: 0.9em;
    font-size: 2.5em;
    margin: 0 0.25em 0 0.25em;
    opacity: 0.50;
    outline: 0;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
  }

  a.router-link-active {
    opacity: 1.0;
  }
  a.router-link-active:after {
    border-bottom-width: 0.5em;
  }

  a:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -1.10em;
    margin-left: -0.5em;
    border-bottom: solid 0em #ffffff;
    border-left: solid 0.5em transparent;
    border-right: solid 0.5em transparent;
    -moz-transition: border-bottom-width .25s ease-in-out;
    -webkit-transition: border-bottom-width .25s ease-in-out;
    -ms-transition: border-bottom-width .25s ease-in-out;
    transition: border-bottom-width .25s ease-in-out;
  }
</style>
