import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faLinkedin, faViadeo, faMedium, faGithub, faMicrosoft, faStrava } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'

library.add(
  fas,
  faTwitter,
  faFacebook,
  faLinkedin,
  faViadeo,
  faMedium,
  faGithub,
  faMicrosoft,
  faStrava
)
dom.watch()

require('./assets/scss/main.scss')

createApp(App)
  .use(store)
  .use(router)
  .component('fa', FontAwesomeIcon)
  .mount('#app')
