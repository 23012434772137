<template>
  <div id="app">

    <NavBarBrand />

    <div class="container is-fluid">

      <div id="nav" class="tabs is-centered is-small">
        <ul>
          <NavTab text="Home" img="home" uri="/" />
          <NavTab text="Work" img="folder" uri="/work" />
          <NavTab text="News" img="newspaper" uri="/news" />
          <NavTab text="Contact" img="envelope" uri="/contact" />
        </ul>
      </div>

      <div id="main">
        <router-view/>
      </div>

    </div>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Handcrafted by me.<br/>
          <a href="http://geoffrey.lalloue.fr">geoffrey.lalloue.fr</a><br/>
          Powered with <img alt="Vue logo" src="./assets/images/vue-logo.png"> Vue.js 3 & <img alt="Bulma logo" src="./assets/images/bulma-logo.png"> Bulma
        </p>
      </div>
    </footer>

  </div>
</template>

<style lang="scss">

/* App */

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

/* Main */

#main {
  background: none;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1);
  -moz-transition: min-height 0.5s ease-in-out, max-height 0.5s ease-in-out;
  -webkit-transition: min-height 0.5s ease-in-out, max-height 0.5s ease-in-out;
  -ms-transition: min-height 0.5s ease-in-out, max-height 0.5s ease-in-out;
  transition: min-height 0.5s ease-in-out, max-height 0.5s ease-in-out;
}
#main > .panel {
  background: #ffffff;
  margin-bottom: 2em;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  margin-bottom: 0;
  position: relative;
  padding: 3.5em 2.5em 2.5em 2.5em;
  width: 100%;
}

/* Nav tabs */

#nav {
  height: 8em;
  cursor: default;
  margin-bottom: 0;
}

/* Footer */

.footer {
  img {
    height: 12px;
  }
  a {
    color: white;
  }
}

</style>

<script lang="ts">
import NavTab from '@/components/NavTab.vue'
import NavBarBrand from '@/components/NavBarBrand.vue'

export default {
  name: 'App',
  components: {
    NavTab,
    NavBarBrand
  }
}
</script>
